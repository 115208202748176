<template>
  <main class="Financial">
    <!-- Components -->
    <router-view @openModalOperation="handleOpenModalOperation" />
    
    <!-- Modal Operation -->
    <DialogOperation ref="DialogOperation" />
  </main>
</template>
<script>
//Views
import { mapState } from 'vuex'
import DialogOperation from '@/views/financial/DialogOperation'
import routes from './index.routes'
export default {
  name: 'Financial',
  components: {
    DialogOperation
  },
  computed: {
    ...mapState({
      permissions: state => state.auth.permissions
    }),
  },
  mounted() {
    this.redirectEmptyPage()
  },
  methods: {
    redirectEmptyPage() {
      const conditional = this.$router.currentRoute.path === '/financial' || this.$router.currentRoute.path === '/financial/'
      if(conditional) {
        const tab = routes[0].children.filter(t => t.isTab).filter(p => this.permissions?.[p.name]?.isVisible).find(el => el)

        if(!tab) this.$router.push({name: 'notfound'})

        this.$router.push({name: tab.name})
      }
    },

    handleOpenModalOperation() {
      this.$refs.DialogOperation.open()
    }
  },
}
</script>
<style scoped lang="scss">
.Financial {
  @media #{$mobile-view} {
    /deep/ .BaseTabs {
      overflow-x: scroll;
      width: 100vw;
    }
  }
}
</style>
