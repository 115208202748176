<template>
  <div class="DialogOperation">          
    <Modal
      v-if="modal"
      @close="close"
    >
      <template #header>
        <div class="Financial__modalHeader">
          Custo da operação
        </div>
      </template>
      <template #body>
        <div class="Financial__modal">
          <DataTable
            :header="operationModalHeader"
            :table="operationTableItems"
            :max-size="false"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { financial } from '@/api'
import { Modal, DataTable } from '@/components/molecules'

export default {
  name: 'DialogOperation',
  components: {
    Modal,
    DataTable
  },
  props: {
    showDialogOperation: {
      type: Boolean,
      require: true,
      default: false
    }
  },
  data() {
    return {
      //Component
      modal: false,

      //Table
      operationModalHeader: {},
      operationTableItems: [],
    }
  },

  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
    }),
    //Handlers
    open() {
      this.modal = true
      this.getDialogData()
      this.$emit('open')
    },

    close() {
      this.modal = false
      this.$emit('close')
    },

    async getDialogData() {
      this.setLoading(true)
      await financial.fetchOperationRule(
        (r) => {
          this.operationModalHeader = r.data.headers
          this.operationTableItems = r.data.content
        },
        (e) => e, () => this.setLoading(false)
      )
    }
  }
}
</script>
<style lang="scss">
.DialogOperation {
  .Modal {
    &__container {
      width: 90%;
      height: 90%;
    }

    .Financial {
      &__modalHeader {
        padding: 2px 12px;
      }
    }
  }
}

</style>